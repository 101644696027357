/* eslint-disable max-len */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { EventBus } from '@primeuix/utils';
import { ConfirmationOptions } from 'primevue/confirmationoptions';
import { inject } from 'vue';
import { useStore } from 'vuex';
import { getModule } from 'vuex-module-decorators';

import { Vue } from 'vue-facing-decorator';
import moment from 'moment-timezone';
import Http from './http';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import messaging from './plugins/firebase';
import AppState from './store/modules/app-state';
import { MeData, Org } from './store/modules/interfaces';
import Me from './store/modules/me';
import RequestsState from './store/modules/requests-state';
import CurrentProperty from './store/modules/current-property';

const browserTimezoneName = moment.tz.guess();

// Register the router hooks with their names
export type { PropType } from 'vue';
export {
  Component, Prop, Emit, toNative, Watch,
} from 'vue-facing-decorator';

export class ComponentBase extends Vue {
  protected readonly vuex = useStore();

  // definitely injected
  protected readonly eventBus = inject<ReturnType<typeof EventBus>>('eventBus') as ReturnType<typeof EventBus>;

  // definitely injected
  protected readonly http = inject<Http>('http') as Http;

  // definitely injected
  protected readonly notifications = inject<typeof messaging>('messaging') as typeof messaging;

  public get AppState() {
    return getModule(AppState, this.vuex);
  }

  protected get RequestsState() {
    return getModule(RequestsState, this.vuex);
  }

  protected get mantis() {
    return this.http.mantis;
  }

  protected get hive() {
    return this.http.hive;
  }

  protected get measurements() {
    return this.http.measurements;
  }

  protected get orgs() {
    return this.http.orgs;
  }

  protected get beetle() {
    return this.http.beetle;
  }

  public get currentOrg(): Org | null {
    return this.AppState.currentOrg;
  }

  public get currentOrgId(): string | null {
    return this.currentOrg?.id ?? null;
  }

  public get currentPropertyId(): string | null {
    return this.$route.params.propertyId as string ?? null;
  }

  public get currentProperty(): CurrentProperty | null {
    return this.AppState.currentProperty;
  }

  public get timezone(): string {
    return this.currentProperty?.timezone ?? browserTimezoneName;
  }

  public get me(): Me | null {
    return this.AppState.me;
  }

  protected async fetchMe() {
    const { data: me } = await this.orgs.get<MeData>('/me', { signal: this.http.signal });
    this.AppState.setMe(me);
  }

  public async logout() {
    this.RequestsState.globalLoading(true);
    this.http.abort('Logout');
    this.eventBus.emit('logout');
    await this.$nextTick();
    await this.$router.replace({ name: 'auth.login' });
    const Cognito = document.querySelector('lar-cognito-config') as HTMLLarCognitoConfigElement;
    if (Cognito) {
      await Cognito.logout()
        .catch((err) => console.error(err));
    } else {
      console.error('lar-cognito-config config element not found. Unable to logout');
    }
    // clear all storages
    this.AppState.clear();
    window.localStorage.clear();
    window.sessionStorage.clear();
    Http.cachedToken = null;
    this.eventBus.emit('logout-done');
    this.RequestsState.globalLoading(false);
  }

  protected async error(reason: any, title?: string): Promise<void> {
    const message = typeof reason === 'string' ? reason : reason?.response?.data?.message || reason?.response?.statusText || reason?.message || 'Unknown Error';
    // TODO: translate?
    const header = title ?? 'Error';
    this.$toast.add({
      severity: 'error',
      summary: header,
      detail: message,
      life: 3000,
    });
  }

  protected async info(reason: string, title?: string): Promise<void> {
    const header = title ?? this.$t('Info');
    this.$toast.add({
      severity: 'info',
      summary: header,
      detail: reason,
      life: 3000,
    });
  }

  protected async success(reason: string, title?: string): Promise<void> {
    const header = title ?? this.$t('Success');
    this.$toast.add({
      severity: 'success',
      summary: header,
      detail: reason,
      life: 3000,
    });
  }

  protected confirm(options?: ConfirmationOptions) {
    this.$confirm.require({
      message: this.$t('Are you sure you want to remove?'),
      header: this.$t('Confirmation'),
      icon: 'pi pi-exclamation-triangle',
      ...options,
      rejectProps: {
        label: this.$t('Cancel'),
        severity: 'secondary',
        text: true,
        ...options?.rejectProps,
      },
      acceptProps: {
        label: this.$t('Remove'),
        severity: 'danger',
        ...options?.acceptProps,
      },
    });
  }
}
