<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="field mb-3">
    <label class="uppercase text-sm font-semibold w-full" :for="`${field.field}-edit`">{{field.label || field.header}}<span class="required" v-if="field.required"> *</span></label>
    <InputText
      v-if="!field.type || field.type === 'email' || field.type === 'username' || field.type === 'text' || field.type === 'tel' || field.type === 'url'"
      :type="field.type"
      v-model="internalVal"
      :id="`${field.field}-edit`"
      :aria-describedby="`${field.field}-help`"
      :placeholder="field.placeholder"
      :required="field.required"
      :disabled="field.disabled || disabled"
      :inputProps="{ autocomplete: field.autocomplete }"
      :invalid="invalid"
    />
    <InputPassword
      v-else-if="field.type === 'password'"
      toggleMask
      :feedback="false"
      v-model="internalVal"
      :id="`${field.field}-edit`"
      :aria-describedby="`${field.field}-help`"
      :placeholder="field.placeholder"
      :required="field.required"
      :disabled="field.disabled || disabled"
      :inputProps="{ autocomplete: field.autocomplete }"
      :invalid="invalid"
    />
    <Textarea
      v-else-if="field.type === 'textarea'"
      toggleMask
      rows="5"
      v-model="internalVal"
      :id="`${field.field}-edit`"
      :aria-describedby="`${field.field}-help`"
      :placeholder="field.placeholder"
      :required="field.required"
      :disabled="field.disabled || disabled"
      :inputProps="{ autocomplete: field.autocomplete }"
      :invalid="invalid"
    />
    <ColorPicker
      v-else-if="field.type === 'color'"
      :inline="true"
      v-model="internalVal"
      :id="`${field.field}-edit`"
      :aria-describedby="`${field.field}-help`"
      :placeholder="field.placeholder"
      :required="field.required"
      :disabled="field.disabled || disabled"
      :invalid="invalid"
    />
    <DatePicker
      v-else-if="field.type === 'datetime'"
      :minDate="field.min && field.min instanceof Date ? field.min : undefined"
      :maxDate="field.max && field.max instanceof Date ? field.max : undefined"
      :showTime="true"
      :showButtonBar="true"
      :hideOnDateTimeSelect="true"
      :numberOfMonths="1"
      v-model="internalVal"
      :id="`${field.field}-edit`"
      :aria-describedby="`${field.field}-help`"
      :placeholder="field.placeholder"
      :required="field.required"
      :disabled="field.disabled || disabled"
      :manualInput="true"
      :showOnFocus="false"
      :showIcon="true"
      :invalid="invalid"
    />
    <InputNumber
      v-else-if="field.type === 'number'"
      :useGrouping="false"
      :min="field.min ? Number(field.min) : undefined"
      :max="field.max ? Number(field.max) : undefined"
      :step="field.step ? Number(field.step) : undefined"
      :mode="field.minFractionDigits ? 'decimal' : undefined"
      :minFractionDigits="field.minFractionDigits"
      :prefix="field.prefix"
      :suffix="field.suffix"
      v-model="internalVal"
      :id="`${field.field}-edit`"
      :aria-describedby="`${field.field}-help`"
      :placeholder="field.placeholder"
      :required="field.required"
      :disabled="field.disabled || disabled"
      :autocomplete="field.autocomplete"
      :invalid="invalid"
    />
    <Checkbox
      v-else-if="field.type === 'checkbox' && !field.options"
      :binary="true"
      :trueValue="true"
      :falseValue="false"
      v-model="internalVal"
      :inputId="`${field.field}-edit`"
      :aria-describedby="`${field.field}-help`"
      :placeholder="field.placeholder"
      :required="field.required"
      :disabled="field.disabled || disabled"
      :min="field.min ? Number(field.min) : undefined"
      :max="field.max ? Number(field.max) : undefined"
      :step="field.step ? Number(field.step) : undefined"
      :invalid="invalid"
    />
    <div v-else-if="field.type === 'checkbox' && field.options" class="flex flex-col">
      <div v-for="option of field.options" :key="option.value" class="flex mt-2 items-center">
        <Checkbox v-model="internalVal" :inputId="option.value" :value="option.value" class="mr-2" />
        <label :for="option.value">{{ option.text }}</label>
      </div>
    </div>
    <Select
      v-else-if="field.type === 'select'"
      :options="field.options"
      optionLabel="text"
      optionValue="value"
      v-model="internalVal"
      :id="`${field.field}-edit`"
      :aria-describedby="`${field.field}-help`"
      :placeholder="field.placeholder"
      :required="field.required"
      :disabled="field.disabled || disabled"
      :editable="field.selectEditable"
      :min="field.min ? Number(field.min) : undefined"
      :max="field.max ? Number(field.max) : undefined"
      :step="field.step ? Number(field.step) : undefined"
      :invalid="invalid"
    />
    <div class="mb-4" v-else-if="field.type === 'slider'">
      <div class="mb-2">{{ internalVal }}</div>
      <Slider
        :min="field.min ? Number(field.min) : undefined"
        :max="field.max ? Number(field.max) : undefined"
        :step="field.step ? Number(field.step) : undefined"
        v-model="internalVal"
        :id="`${field.field}-edit`"
        :aria-describedby="`${field.field}-help`"
        :placeholder="field.placeholder"
        :required="field.required"
        :disabled="field.disabled || disabled"
        :autocomplete="field.autocomplete"
        :invalid="invalid"
      />
    </div>
    <DatePicker
      v-else-if="field.type === 'time'"
      timeOnly
      :useGrouping="false"
      :minDate="field.min && field.min instanceof Date ? field.min : undefined"
      :maxDate="field.max && field.max instanceof Date ? field.max : undefined"
      :max="field.max ? field.max : undefined"
      :step="field.step ? Number(field.step) : undefined"
      v-model="internalVal"
      :id="`${field.field}-edit`"
      :aria-describedby="`${field.field}-help`"
      :placeholder="field.placeholder"
      :required="field.required"
      :disabled="field.disabled || disabled"
      :autocomplete="field.autocomplete"
      :invalid="invalid"
    />
    <!--- TODO: other types -->
    <template v-else>
      <p>ERROR: INPUT TYPE NOT IMPLEMENTED</p>
    </template>

    <small v-if="field.help" :id="`${field.field}-help`">{{field.help}}</small>
  </div>
</template>

<style lang="scss">
.field .p-dropdown, .field .p-inputnumber, .p-fluid .p-dropdown {
  display: flex !important;
}
.required {
  display: inline;
  color: var(--lar-color-danger);
}
</style>

<style lang="scss" scoped>
small {
  white-space: pre-line;
}
.field > * {
    display: flex;
}
</style>

<script lang="ts">
// @TODO: fix date form, load it from Moment
/* eslint-disable @typescript-eslint/no-explicit-any */
import Checkbox from 'primevue/checkbox';
import ColorPicker from 'primevue/colorpicker';
import DatePicker from 'primevue/datepicker';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import InputPassword from 'primevue/password';
import Select from 'primevue/select';
import Slider from 'primevue/slider';
import Textarea from 'primevue/textarea';

import {
  Component, ComponentBase, Prop,
  PropType,
  toNative,
} from '@/component-base';

import moment from 'moment';
import { BlueprintField } from '../interfaces/blueprint-data';

@Component({
  emits: ['update:modelValue'], // use v-model
  components: {
    InputText, InputNumber, InputPassword, Textarea, ColorPicker, Checkbox, DatePicker, Select, Slider,
  },
})
class BlueprintEditItemField extends ComponentBase {
  @Prop({ type: Object as PropType<BlueprintField>, required: true })
  declare public field: BlueprintField;

  @Prop
  declare public modelValue: any;

  @Prop({ type: Boolean, required: false })
  declare public disabled?: boolean;

  @Prop
  declare public invalid: boolean;

  public get internalVal() {
    if (this.field.type === 'time' && this.modelValue !== null && typeof this.modelValue === 'string') {
      const [hour, minute] = this.modelValue.split(':');
      return moment().set({
        hour: Number(hour), minute: Number(minute), second: 0, millisecond: 0,
      }).toDate();
    }
    return this.modelValue;
  }

  public set internalVal(val: any) {
    if (this.field.type === 'time' && val !== null && val instanceof Date) {
      const time = moment(val).format('HH:mm');
      this.$emit('update:modelValue', time);
      return;
    }
    this.$emit('update:modelValue', val);
  }
}

export default toNative(BlueprintEditItemField);
</script>
