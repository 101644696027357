/* eslint-disable no-shadow */
import { FilterMatchMode } from '@primevue/core/api';
import { ColumnProps } from 'primevue/column';

export { FilterMatchMode };
export type CallbackFunc = 'onFilter' | 'newItem' | 'beforeSave' | 'afterSave' | 'editItem' | 'deleteItem' | 'beforeDelete' | 'afterDelete';

export type DefaultEntityType = Record<string, any>;

export enum InputTypeEnum {
  text = 1,
  email,
  username,
  password,
  url,
  tel,
  date,
  time,
  number,
  range,
  color,
  slider,
}

export enum CheckboxTypeEnum {
  checkbox = 1,
}

export enum SelectTypeEnum {
  select = 1,
}

export enum DateTypeEnum {
  datetime = 1,
}

export type EditFieldType = keyof typeof InputTypeEnum | keyof typeof CheckboxTypeEnum | keyof typeof SelectTypeEnum | keyof typeof DateTypeEnum | /* 'key-value-pairs' | */ 'textarea';

export const BlueprintFilterMatchMode = Object.assign(FilterMatchMode, { IS_NULL: '$isnull', NOT_NULL: '$notnull' });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface BlueprintFilterItem { value: any, matchMode: string }

export interface BlueprintFilter { [key: string]: BlueprintFilterItem }

export interface BlueprintFieldProps<TEntity extends DefaultEntityType = DefaultEntityType> extends Pick<ColumnProps, 'header' | 'sortable' | 'sortField' | 'filterMatchMode' | 'headerStyle'> {
  field: keyof TEntity | string;
  noNew?: boolean;
  noEdit?: boolean;
  noList?: boolean;
  searchable?: boolean; // legacy fallback
  filterField?: string;
  filterable?: boolean;
  placeholder?: string;
  type?: EditFieldType;
  filterDefaultValue?: any;
}
export type FieldOptions = { value: any; text: string };
export interface BlueprintEditFieldOptions {
  type?: EditFieldType;
  label?: string;
  // labelInfo?: string;
  min?: string | number | Date;
  max?: string | number | Date;
  minFractionDigits?: number;
  prefix?: string;
  suffix?: string;
  selectEditable?: boolean;
  step?: string | number;
  required?: boolean;
  disabled?: boolean;
  labels?: { checked: string; unchecked: string } | boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: FieldOptions[];
  placeholder?: string;
  // state?: string; // @TODO
  hidden?: boolean;
  //
  help?: string;
  autocomplete?: string
}
export interface BlueprintCSVFieldOptions {
  CSVFormatter?: (item: any) => Promise<any> | any;
  noCSV?: boolean;
}

export type BlueprintField<TEntity extends DefaultEntityType = DefaultEntityType> = (BlueprintFieldProps<TEntity> & BlueprintEditFieldOptions & BlueprintCSVFieldOptions);
