import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "field mb-3" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "required"
}
const _hoisted_4 = {
  key: 7,
  class: "flex flex-col"
}
const _hoisted_5 = ["for"]
const _hoisted_6 = {
  key: 9,
  class: "mb-4"
}
const _hoisted_7 = { class: "mb-2" }
const _hoisted_8 = { key: 11 }
const _hoisted_9 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputPassword = _resolveComponent("InputPassword")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_ColorPicker = _resolveComponent("ColorPicker")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: "uppercase text-sm font-semibold w-full",
      for: `${_ctx.field.field}-edit`
    }, [
      _createTextVNode(_toDisplayString(_ctx.field.label || _ctx.field.header), 1),
      (_ctx.field.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " *"))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2),
    (!_ctx.field.type || _ctx.field.type === 'email' || _ctx.field.type === 'username' || _ctx.field.type === 'text' || _ctx.field.type === 'tel' || _ctx.field.type === 'url')
      ? (_openBlock(), _createBlock(_component_InputText, {
          key: 0,
          type: _ctx.field.type,
          modelValue: _ctx.internalVal,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalVal) = $event)),
          id: `${_ctx.field.field}-edit`,
          "aria-describedby": `${_ctx.field.field}-help`,
          placeholder: _ctx.field.placeholder,
          required: _ctx.field.required,
          disabled: _ctx.field.disabled || _ctx.disabled,
          inputProps: { autocomplete: _ctx.field.autocomplete },
          invalid: _ctx.invalid
        }, null, 8, ["type", "modelValue", "id", "aria-describedby", "placeholder", "required", "disabled", "inputProps", "invalid"]))
      : (_ctx.field.type === 'password')
        ? (_openBlock(), _createBlock(_component_InputPassword, {
            key: 1,
            toggleMask: "",
            feedback: false,
            modelValue: _ctx.internalVal,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.internalVal) = $event)),
            id: `${_ctx.field.field}-edit`,
            "aria-describedby": `${_ctx.field.field}-help`,
            placeholder: _ctx.field.placeholder,
            required: _ctx.field.required,
            disabled: _ctx.field.disabled || _ctx.disabled,
            inputProps: { autocomplete: _ctx.field.autocomplete },
            invalid: _ctx.invalid
          }, null, 8, ["modelValue", "id", "aria-describedby", "placeholder", "required", "disabled", "inputProps", "invalid"]))
        : (_ctx.field.type === 'textarea')
          ? (_openBlock(), _createBlock(_component_Textarea, {
              key: 2,
              toggleMask: "",
              rows: "5",
              modelValue: _ctx.internalVal,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.internalVal) = $event)),
              id: `${_ctx.field.field}-edit`,
              "aria-describedby": `${_ctx.field.field}-help`,
              placeholder: _ctx.field.placeholder,
              required: _ctx.field.required,
              disabled: _ctx.field.disabled || _ctx.disabled,
              inputProps: { autocomplete: _ctx.field.autocomplete },
              invalid: _ctx.invalid
            }, null, 8, ["modelValue", "id", "aria-describedby", "placeholder", "required", "disabled", "inputProps", "invalid"]))
          : (_ctx.field.type === 'color')
            ? (_openBlock(), _createBlock(_component_ColorPicker, {
                key: 3,
                inline: true,
                modelValue: _ctx.internalVal,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.internalVal) = $event)),
                id: `${_ctx.field.field}-edit`,
                "aria-describedby": `${_ctx.field.field}-help`,
                placeholder: _ctx.field.placeholder,
                required: _ctx.field.required,
                disabled: _ctx.field.disabled || _ctx.disabled,
                invalid: _ctx.invalid
              }, null, 8, ["modelValue", "id", "aria-describedby", "placeholder", "required", "disabled", "invalid"]))
            : (_ctx.field.type === 'datetime')
              ? (_openBlock(), _createBlock(_component_DatePicker, {
                  key: 4,
                  minDate: _ctx.field.min && _ctx.field.min instanceof Date ? _ctx.field.min : undefined,
                  maxDate: _ctx.field.max && _ctx.field.max instanceof Date ? _ctx.field.max : undefined,
                  showTime: true,
                  showButtonBar: true,
                  hideOnDateTimeSelect: true,
                  numberOfMonths: 1,
                  modelValue: _ctx.internalVal,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.internalVal) = $event)),
                  id: `${_ctx.field.field}-edit`,
                  "aria-describedby": `${_ctx.field.field}-help`,
                  placeholder: _ctx.field.placeholder,
                  required: _ctx.field.required,
                  disabled: _ctx.field.disabled || _ctx.disabled,
                  manualInput: true,
                  showOnFocus: false,
                  showIcon: true,
                  invalid: _ctx.invalid
                }, null, 8, ["minDate", "maxDate", "modelValue", "id", "aria-describedby", "placeholder", "required", "disabled", "invalid"]))
              : (_ctx.field.type === 'number')
                ? (_openBlock(), _createBlock(_component_InputNumber, {
                    key: 5,
                    useGrouping: false,
                    min: _ctx.field.min ? Number(_ctx.field.min) : undefined,
                    max: _ctx.field.max ? Number(_ctx.field.max) : undefined,
                    step: _ctx.field.step ? Number(_ctx.field.step) : undefined,
                    mode: _ctx.field.minFractionDigits ? 'decimal' : undefined,
                    minFractionDigits: _ctx.field.minFractionDigits,
                    prefix: _ctx.field.prefix,
                    suffix: _ctx.field.suffix,
                    modelValue: _ctx.internalVal,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.internalVal) = $event)),
                    id: `${_ctx.field.field}-edit`,
                    "aria-describedby": `${_ctx.field.field}-help`,
                    placeholder: _ctx.field.placeholder,
                    required: _ctx.field.required,
                    disabled: _ctx.field.disabled || _ctx.disabled,
                    autocomplete: _ctx.field.autocomplete,
                    invalid: _ctx.invalid
                  }, null, 8, ["min", "max", "step", "mode", "minFractionDigits", "prefix", "suffix", "modelValue", "id", "aria-describedby", "placeholder", "required", "disabled", "autocomplete", "invalid"]))
                : (_ctx.field.type === 'checkbox' && !_ctx.field.options)
                  ? (_openBlock(), _createBlock(_component_Checkbox, {
                      key: 6,
                      binary: true,
                      trueValue: true,
                      falseValue: false,
                      modelValue: _ctx.internalVal,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.internalVal) = $event)),
                      inputId: `${_ctx.field.field}-edit`,
                      "aria-describedby": `${_ctx.field.field}-help`,
                      placeholder: _ctx.field.placeholder,
                      required: _ctx.field.required,
                      disabled: _ctx.field.disabled || _ctx.disabled,
                      min: _ctx.field.min ? Number(_ctx.field.min) : undefined,
                      max: _ctx.field.max ? Number(_ctx.field.max) : undefined,
                      step: _ctx.field.step ? Number(_ctx.field.step) : undefined,
                      invalid: _ctx.invalid
                    }, null, 8, ["modelValue", "inputId", "aria-describedby", "placeholder", "required", "disabled", "min", "max", "step", "invalid"]))
                  : (_ctx.field.type === 'checkbox' && _ctx.field.options)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.field.options, (option) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: option.value,
                            class: "flex mt-2 items-center"
                          }, [
                            _createVNode(_component_Checkbox, {
                              modelValue: _ctx.internalVal,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.internalVal) = $event)),
                              inputId: option.value,
                              value: option.value,
                              class: "mr-2"
                            }, null, 8, ["modelValue", "inputId", "value"]),
                            _createElementVNode("label", {
                              for: option.value
                            }, _toDisplayString(option.text), 9, _hoisted_5)
                          ]))
                        }), 128))
                      ]))
                    : (_ctx.field.type === 'select')
                      ? (_openBlock(), _createBlock(_component_Select, {
                          key: 8,
                          options: _ctx.field.options,
                          optionLabel: "text",
                          optionValue: "value",
                          modelValue: _ctx.internalVal,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.internalVal) = $event)),
                          id: `${_ctx.field.field}-edit`,
                          "aria-describedby": `${_ctx.field.field}-help`,
                          placeholder: _ctx.field.placeholder,
                          required: _ctx.field.required,
                          disabled: _ctx.field.disabled || _ctx.disabled,
                          editable: _ctx.field.selectEditable,
                          min: _ctx.field.min ? Number(_ctx.field.min) : undefined,
                          max: _ctx.field.max ? Number(_ctx.field.max) : undefined,
                          step: _ctx.field.step ? Number(_ctx.field.step) : undefined,
                          invalid: _ctx.invalid
                        }, null, 8, ["options", "modelValue", "id", "aria-describedby", "placeholder", "required", "disabled", "editable", "min", "max", "step", "invalid"]))
                      : (_ctx.field.type === 'slider')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.internalVal), 1),
                            _createVNode(_component_Slider, {
                              min: _ctx.field.min ? Number(_ctx.field.min) : undefined,
                              max: _ctx.field.max ? Number(_ctx.field.max) : undefined,
                              step: _ctx.field.step ? Number(_ctx.field.step) : undefined,
                              modelValue: _ctx.internalVal,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.internalVal) = $event)),
                              id: `${_ctx.field.field}-edit`,
                              "aria-describedby": `${_ctx.field.field}-help`,
                              placeholder: _ctx.field.placeholder,
                              required: _ctx.field.required,
                              disabled: _ctx.field.disabled || _ctx.disabled,
                              autocomplete: _ctx.field.autocomplete,
                              invalid: _ctx.invalid
                            }, null, 8, ["min", "max", "step", "modelValue", "id", "aria-describedby", "placeholder", "required", "disabled", "autocomplete", "invalid"])
                          ]))
                        : (_ctx.field.type === 'time')
                          ? (_openBlock(), _createBlock(_component_DatePicker, {
                              key: 10,
                              timeOnly: "",
                              useGrouping: false,
                              minDate: _ctx.field.min && _ctx.field.min instanceof Date ? _ctx.field.min : undefined,
                              maxDate: _ctx.field.max && _ctx.field.max instanceof Date ? _ctx.field.max : undefined,
                              max: _ctx.field.max ? _ctx.field.max : undefined,
                              step: _ctx.field.step ? Number(_ctx.field.step) : undefined,
                              modelValue: _ctx.internalVal,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.internalVal) = $event)),
                              id: `${_ctx.field.field}-edit`,
                              "aria-describedby": `${_ctx.field.field}-help`,
                              placeholder: _ctx.field.placeholder,
                              required: _ctx.field.required,
                              disabled: _ctx.field.disabled || _ctx.disabled,
                              autocomplete: _ctx.field.autocomplete,
                              invalid: _ctx.invalid
                            }, null, 8, ["minDate", "maxDate", "max", "step", "modelValue", "id", "aria-describedby", "placeholder", "required", "disabled", "autocomplete", "invalid"]))
                          : (_openBlock(), _createElementBlock("p", _hoisted_8, "ERROR: INPUT TYPE NOT IMPLEMENTED")),
    (_ctx.field.help)
      ? (_openBlock(), _createElementBlock("small", {
          key: 12,
          id: `${_ctx.field.field}-help`
        }, _toDisplayString(_ctx.field.help), 9, _hoisted_9))
      : _createCommentVNode("", true)
  ]))
}